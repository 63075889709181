import Lottie from "react-lottie-player";

import phone from "../../lotties/phone.json";

export function Phone() {
    return (
        <>
            <a href="tel:0134840066">
                <span className="flex hover:bg-red-700 hover:text-white px-3 py-2 rounded-md font-medium">
                    <Lottie
                        loop
                        animationData={phone}
                        play
                        style={{ width: 25, height: 25 }}
                    />
                    01 34 84 00 66
                </span>
            </a>
        </>
    );
}