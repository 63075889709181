import React from 'react';
import Swal from 'sweetalert2';
import Cors from '../components/Navbar/Cors';

export default function Documents () {
    const documents = [
			{ titre: "Demande d’inscription", url: "https://docs.google.com/forms/d/e/1FAIpQLSfeNFNwBRePnd3SIECT9y8iktAk4dNisvp1m9NejYhxTDt5cQ/viewform" },
			{ titre: "Règlement intérieur", url: "reglement-interieur.pdf" },
			{ titre: "CGV", url: "" },
			{ titre: "CGU Ediser", url: "cgu-ediser.pdf" },
			{ titre: "Organigramme et Contacts", url: "organigramme.pdf" },
			{ titre: "Certificat Qualiopi", url: "" },
			{ titre: "Forms réclamation", url: "https://docs.google.com/forms/d/e/1FAIpQLSc-8lp-LEXobb6xyG5S5P5hQgNsNzW12XAnX3EebznxrHX1lQ/viewform" }
    ];

		const onClick = () => {
			Swal.fire({
					title:"Processus de certification en cours", 
					confirmButtonText: "Compris"
			});
		};

	return (
		<div className='background-sysy'>
			<Cors>
				<h1 className='text-5xl'>Documents associés</h1>
				<div className="bg-gray-200 rounded-xl">
					<div className="m-8 p-4" style={{height: "80vh"}}>
						<ul className="list-disc text-lg">
							{
								documents.map((value, key) => {
									if (value.url === "") {
										return (
											<li key={key} className="my-4">
												<button 
													onClick={onClick}
													className="text-blue-500 hover:text-blue-700"
												>
													{value.titre}
												</button>
											</li>
										)
									} else {
										return (
											<li key={key} className="my-4">
												<a 
														target='_blank'
														rel="noreferrer"
														className="text-blue-500 hover:text-blue-700"
														href={value.url}
												>
														{value.titre}
												</a>
											</li>
										)
									}
								})
							}
						</ul>
					</div>
				</div>
			</Cors>
		</div>
  	)
}