import React, { useState } from 'react';
import Cors from '../components/Navbar/Cors';
import ReactCardFlip from 'react-card-flip';

import { CardPermisMoto } from '../components/CardPermisMoto/CardPermisMoto';
import { CardPermisBack } from '../components/CardPermisBack/CardPermisBack';

import scooter from "../asset/moto/scooter.png";
import cb500 from "../asset/moto/cb500.png";
import cb125r from "../asset/moto/cb125r.png";

export default function PermisMoto () {

    const explicationMoto = "Toutes les motos ci dessous sont celles avec lesquelles nous effectuons les différentes formations."

    const textMotoAlerte = "Piste privée à 5 minutes de l'auto école.";

    const permisMotos = [
        {
            titre: "Permis AM",
            description: "Le permis AM, anciennement brevet de sécurité routière (BSR), est une catégorie de permis de conduire français autorisant son titulaire à conduire un cyclomoteur ou un quadricycle léger à moteur (voiturette ou quad).",
            image: scooter,
            badge: [
                "À partir de 14 ans",
                <>50 cm<sup>3</sup></>
            ],
            descriptionBis: 
                <>
                    <h2 className="text-base text-bold">Le formation comprend :</h2>
                    <ul className="list-disc py-4 px-4">
                        <li>Inscription (au fichier national des permis de conduire)</li>
                        <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                        <li>8 heures de formation</li>
                    </ul>
                </>
        },
        {
            titre: "Permis A1",
            description: "Le permis A1 permet de conduire une motocyclette avec ou sans side-car, d’une cylindrée maximale de 125 cm3, d’une puissance n’excédant pas 11 kilowatts et dont le rapport puissance/poids ne dépasse pas 0,2 kilowatt par kilogramme. Pour les titulaires du permis B depuis plus de 2 ans, il suffit d'une formation de 7 heures pour l'obtenir.",
            image: cb125r,
            badge: [
                "À partir de 16 ans",
                <>125 cm<sup>3</sup></>
            ],
            descriptionBis:  
                <>
                    <h2 className="text-base text-bold">Le formation comprend :</h2>
                    <ul className="list-disc py-4 px-4">
                        <li>Inscription (au fichier national des permis de conduire)</li>
                        <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage, livre de code)</li>
                        <li>Évaluation de départ</li>
                        <li>20 heures de conduite</li>
                        <li>Frais d'inscription aux examens plateau et circulation</li>
                    </ul>
                    <h1 className="font-bold text-xl mb-2">Passerelle A1 pour les titulaires du permis B</h1>
                    <h2 className="text-base text-bold">2 ans de permis B minimum</h2>
                    <ul className="list-disc py-4 px-4">
                        <li>Inscription</li>
                        <li>Kit Pédagogique</li>
                        <li>1 heures de théorie</li>
                        <li>6 heures de conduite</li>
                    </ul>
                </>
        },
        {
            titre: "Permis A2",
            description: "Le permis A2 permet de conduire une motocyclette avec ou sans side-car d’une puissance n’excédant pas 15 kilowatts et dont le rapport puissance/poids ne dépasse pas 0,1 kilowatt par kilogramme. La puissance ne peut résulter du bridge d’un véhicule développant plus de 70kW.",
            image: cb500,
            badge: [
                "À partir de 18 ans"
            ],
            descriptionBis: 
                <>
                    <h2 className="text-base text-bold">Le formation comprend :</h2>
                    <ul className="list-disc py-4 px-4">
                        <li>Inscription (au fichier national des permis de conduire)</li>
                        <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage, livre de code)</li>
                        <li>Évaluation de départ</li>
                        <li>20 heures de conduite</li>
                        <li>Épreuve Théorique Moto (ETM)</li>
                        <li>Frais d'inscription aux examens plateau et circulation</li>
                    </ul>
                </>
        },
        {
            titre: "Passerelle A2 vers A",
            description: "La permis A permet de conduire de toutes les motocyclettes et tricycles ainsi que les quadricycles à moteur d’une puissance maximum de 15 kW. Pour les titulaires du permis A2 depuis plus de 2 ans, il suffit d'une formation de 7 heures pour l'obtenir.",
            image: cb500,
            badge: [
                "À partir de 18 ans"
            ],
            descriptionBis: 
                <>
                    <h2 className="text-base text-bold pb-4">2 ans de permis A2 minimum</h2>
                    <h2 className="text-base text-bold">Le formation comprend :</h2>
                    <ul className="list-disc py-4 px-4">
                        <li>Inscription (au fichier national des permis de conduire)</li>
                        <li>Kit Pédagogique</li>
                        <li>1 heures de théorie</li>
                        <li>6 heures de conduite</li>
                    </ul>
                    <p>*Tarif préférentiel pour les élèves ayant passé leur permis A2 chez SYSY AUTO ECOLE</p>
                </>
        }
    ];

    const documents = [
        "1 photocopie recto verso de la carte d’identité en cours de validité",
        "1 photocopie de la carte de séjour pour les nationalités étrangères",
        "1 justificatif de domicile de moins de 3 mois",
        "1 attestation d'hébergement écrite, datée et signée à la main",
        "1 photocopie de l'ASSR2",
        "1 photocopie de la carte d’identité du père ou de la mère pour les mineurs",
        "1 photocopie de l’attestation de recensement pour les 16-18 ans",
        "1 photocopie de l’attestation JAPD pour les plus de 18 ans",
        "4 photos agrées ANTS",
        "4 enveloppes timbrées"
    ];

    const nomBoutonRetourner = "Cliquer pour voir la formule";
    const nomBoutonRetournerBack = "Cliquer pour voir la formation";

    const [isFlippedAM, setIsFlippedAM ] = useState(0);
    const [isFlippedA1, setIsFlippedA1 ] = useState(0);
    const [isFlippedA2, setIsFlippedA2 ] = useState(0);
    const [isFlippedA, setIsFlippedA ] = useState(0);

    const handleClickAM = (e) => {
        e.preventDefault();
        setIsFlippedAM(!isFlippedAM);
    };
    
    const handleClickA1 = (e) => {
        e.preventDefault();
        setIsFlippedA1(!isFlippedA1);
    };
    
    const handleClickA2 = (e) => {
        e.preventDefault();
        setIsFlippedA2(!isFlippedA2);
    };
    
    const handleClickA = (e) => {
        e.preventDefault();
        setIsFlippedA(!isFlippedA);
    };

    return (
        <div className='background-sysy'>
            <Cors>
                <div className="text-bold flex justify-between">
                    <h1 className='text-5xl'>Permis Moto</h1>
                    <a 
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfeNFNwBRePnd3SIECT9y8iktAk4dNisvp1m9NejYhxTDt5cQ/viewform'
                        target='_blank'
                        rel="noreferrer"
                        className='bg-red-700 text-white hover:underline px-3 py-2 rounded-md font-medium text-center grid content-center'
                    >
                        Demande d’inscription
                    </a>
                </div>
                <div className="bg-gray-200 rounded-xl ">
                    <p className="text-xl text-center py-4 leading-loose my-2 mx-4">
                        {explicationMoto}
                    </p>
                    <p className="text-red-500 text-lg text-center leading-loose px-2 py-2 mb-4 mx-4">
                        {textMotoAlerte}
                    </p>
                </div>
                <div className="md:flex py-4">
                    <ReactCardFlip isFlipped={isFlippedAM}>
                            <CardPermisMoto  
                                    titre={permisMotos[0].titre} 
                                    description={permisMotos[0].description} 
                                    image={permisMotos[0].image} 
                                    badge={permisMotos[0].badge}
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickAM}>{nomBoutonRetourner}</button>
                            </CardPermisMoto>
                            <CardPermisBack
                                titre={permisMotos[0].titre} 
                                content={permisMotos[0].descriptionBis} 
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickAM}>{nomBoutonRetournerBack}</button>
                            </CardPermisBack>
                    </ReactCardFlip>
                    <ReactCardFlip isFlipped={isFlippedA1}>
                            <CardPermisMoto  
                                    titre={permisMotos[1].titre} 
                                    description={permisMotos[1].description} 
                                    image={permisMotos[1].image} 
                                    badge={permisMotos[1].badge}
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA1}>{nomBoutonRetourner}</button>
                            </CardPermisMoto>
                            <CardPermisBack
                                titre={permisMotos[1].titre} 
                                content={permisMotos[1].descriptionBis} 
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA1}>{nomBoutonRetournerBack}</button>
                            </CardPermisBack>
                    </ReactCardFlip>
                    <ReactCardFlip isFlipped={isFlippedA2}>
                            <CardPermisMoto  
                                    titre={permisMotos[2].titre} 
                                    description={permisMotos[2].description} 
                                    image={permisMotos[2].image} 
                                    badge={permisMotos[2].badge}
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA2}>{nomBoutonRetourner}</button>
                            </CardPermisMoto>
                            <CardPermisBack
                                titre={permisMotos[2].titre} 
                                content={permisMotos[2].descriptionBis} 
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA2}>{nomBoutonRetournerBack}</button>
                            </CardPermisBack>
                    </ReactCardFlip>
                    <ReactCardFlip isFlipped={isFlippedA}>
                            <CardPermisMoto  
                                    titre={permisMotos[3].titre} 
                                    description={permisMotos[3].description} 
                                    image={permisMotos[3].image} 
                                    badge={permisMotos[3].badge}
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA}>{nomBoutonRetourner}</button>
                            </CardPermisMoto>
                            <CardPermisBack
                                titre={permisMotos[3].titre} 
                                content={permisMotos[3].descriptionBis} 
                            >
                                <button className="px-2 py-2 underline" onClick={handleClickA}>{nomBoutonRetournerBack}</button>
                            </CardPermisBack>
                    </ReactCardFlip>
                </div>
                <div className="bg-gray-200 rounded-xl">
                    <div className="mx-4 px-2">
                        <div className="font-bold text-xl mb-2">Documents à fournir pour l'inscription :</div>
                        <ul className="list-disc">
                            {
                                documents.map((value, key) => {
                                    return (
                                        <li key={key}>{value}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </Cors>
        </div>
    )
}