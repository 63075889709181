import { Link } from "./Link";

export function AllLink() {

    const classLink = "hover:bg-red-700 hover:text-white px-3 py-2 rounded-md font-medium text-center grid content-center";

    return (
        <>
            <Link label="Permis Voiture" url="/permis-voiture" classNom={classLink}/>
            <Link label="Permis Moto" url="/permis-moto" classNom={classLink}/>
            <Link label="Financements" url="/financements" classNom={classLink}/>
            <Link label="Documents associés" url="/documents" classNom={classLink}/>
        </>
    );
}

export function AllLinkDropDown() {

    const classDropdown = "hover:bg-red-700 hover:text-white px-3 py-2 rounded-md font-medium block text-center grid content-center";

    return (
        <>
            <Link label="Permis Voiture" url="/permis-voiture" classNom={classDropdown}/>
            <Link label="Permis Moto" url="/permis-moto" classNom={classDropdown}/>G
            <Link label="Financements" url="/financements" classNom={classDropdown}/>
            <Link label="Documents associés" url="/documents" classNom={classDropdown}/>
        </>
    );
}