export function CardPermisBack({titre, content, children}) {
    return (
        <div className="py-4 px-4 rounded-lg">
            <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div scale-100 hover:scale-105 duration-300 ease-in-out">
                <div className="bg-red-300 rounded-lg">
                    <div className="px-4 py-4">
                        <div className="font-bold text-xl mb-2">
                            {titre}
                        </div>
                        {content}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}