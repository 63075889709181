import { NavLink } from "react-router-dom";

export function Link({label, url, classNom}) {

    const classInactive = classNom;
    const classActive = "bg-red-700 text-white px-3 py-2 rounded-md font-medium text-center grid content-center";

    return (
        <NavLink
            to={url} 
            className={
                ({ isActive }) => isActive ? classActive : classInactive
            }
        >
            {label}
        </NavLink>
    );
}